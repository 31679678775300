import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from './Layout';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import { FeaturedArticle, ArticleGrid } from '~/components/landing/ResourcesLanding';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function CategoryPage({ data: { wp, wpCategory, allWpPost } }) {
  const footerBlock = wp.footer?.globalFooter?.footer?.resourcesCta?.layouts;
  const [page, setPage] = useState(1);

  const per_page = 12;

  const last_visible = 1 + per_page * page;

  const visiblePosts = allWpPost.nodes.slice(1, last_visible);
  const show_load_more = last_visible < allWpPost.nodes.length;

  return (
    <Layout wp={wp}>
      <Seo post={wpCategory} />

      <section className="container section section-top section-header text-center">
        <h1 className="t-52">{wpCategory.name}</h1>
      </section>
      {allWpPost && allWpPost.nodes && (
        <>
          <FeaturedArticle post={allWpPost.nodes[0]} />
          {allWpPost.nodes.length > 1 && (
            <div className="my-20">
              <ArticleGrid posts={visiblePosts} label={wpCategory.name} />
            </div>
          )}
        </>
      )}
      {show_load_more && (
        <div className="text-center mb-12">
          <button onClick={() => setPage(page + 1)} className="button">
            Load more
          </button>
        </div>
      )}
      {footerBlock && <LayoutRenderer prefix="ReusableBlock_Layouts_Sections_Section_Layout_" layouts={footerBlock} />}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Category($id: String!) {
    wp {
      ...GeneratedWp
    }
    wpCategory(id: { eq: $id }) {
      name
      slug
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          raw
        }
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: 99
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        link
        featuredImage {
          node {
            ...Image
          }
        }
        date
        categories {
          nodes {
            name
            link
            taxonomyCategory {
              icon {
                ...Image
              }
            }
          }
        }
        containerArticle {
          masthead {
            logo {
              ...Image
            }
            summary
          }
        }
      }
    }
  }
`;
